import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["arrow", "button", "content", "expand", "collapse"];

  collapse() {
    this.contentTarget.style.maxHeight = this.contentTarget.scrollHeight + "px";
    this.arrowTarget.classList.remove("rotate-180");
    this.buttonTarget.dataset.action = "click->collapse#uncollapse";
  }

  uncollapse() {
    this.contentTarget.style.maxHeight = null;
    this.arrowTarget.classList.add("rotate-180");
    this.buttonTarget.dataset.action = "click->collapse#collapse";
  }

  expand() {
    this.contentTarget.style.maxHeight = this.contentTarget.scrollHeight + "px";
  }

  contract() {
    setTimeout(() => {
      this.contentTarget.style.maxHeight = null;
    });
  }
}
