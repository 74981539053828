window.onscroll = function (e) {
  const topMenu = document.getElementById("TopMenu");

  if (topMenu) {
    const scrolledPixels = window.pageYOffset;

    if (scrolledPixels > 50 && !topMenu.classList.contains("opened-menu")) {
      topMenu.classList.remove("nonscrolling-menu");
      topMenu.classList.add("scrolling-menu");
      if (document.getElementById("MobileMenu")) {
        document
          .getElementById("MobileMenu")
          .classList.add("scrolling-mobile-menu");
      }
    }

    if (scrolledPixels < 50 && !topMenu.classList.contains("opened-menu")) {
      topMenu.classList.add("nonscrolling-menu");
      topMenu.classList.remove("scrolling-menu");
      if (document.getElementById("MobileMenu")) {
        document
          .getElementById("MobileMenu")
          .classList.remove("scrolling-mobile-menu");
      }
    }
  }
};
