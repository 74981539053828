import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://be2893cc365d41a0b89105eb90f70ae4@o1051237.ingest.us.sentry.io/6034247",
  integrations: [
    Sentry.browserTracingIntegration()
  ],
  tracesSampleRate: 0.1
});

import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Styles
import '../entrypoints/application.css';
import '../stylesheets/base.scss'
import '../stylesheets/components.scss'
import '../stylesheets/utilities.scss'

import '../components/scrolling-menu.js'
import '../components/hamburger-icon-animation.js'
import '../components/turbo-script-reloader.js'
import '../components/screen-orientation-reloader.js'

// Rails functionality
import Rails from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'

// Make accessible for Electron and Mobile adapters
window.Rails = Rails
window.Turbo = Turbo

try { Rails.start() } catch { }