import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'input', 'error', 'securityError', 'turnstile']
  static values = { renderedTurnstile: Boolean }

  renderTurnstile() {
    if (this.renderedTurnstileValue) return

    turnstile.render(this.turnstileTarget)
    this.renderedTurnstileValue = true
  }

  submit(event) {
    let valid = true
    // Check validity of fields
    this.inputTargets.forEach((input) => {
      if (!input.checkValidity()) {
        valid = false
        input.focus()
        input.blur()
      }
    })

    if (!valid) {
      this.errorTarget.classList.remove('hidden')
      return
    }

    // Check cloudflare turnstile for spammers. If response is undefined, security failed and we
    // throw an error. If there's a response string, security passed and we continue with validation
    const response = turnstile.getResponse(this.turnstileTarget)

    if (!response) {
      this.securityErrorTarget.classList.remove('hidden')
      return
    }

    const errorTarget = this.errorTarget
    const securityErrorTarget = this.securityErrorTarget
    const formTarget = this.formTarget
    const turnstileTarget = this.turnstileTarget

    Rails.ajax({
      type: 'post',
      url: this.formTarget.action,
      data: new FormData(this.formTarget),
      success: function () {
        errorTarget.classList.add('hidden')
        securityErrorTarget.classList.add('hidden')
        formTarget.reset()
        turnstile.reset(turnstileTarget)
        MicroModal.close('contact-us')
        MicroModal.show('contact-us-thanks')
      },
      error: function () {
        securityErrorTarget.classList.remove('hidden')
      }
    })
  }
}
