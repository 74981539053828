import { Controller } from 'stimulus'
import MicroModal from 'micromodal'

export default class extends Controller {

  open(event) {
    const day = event.currentTarget.dataset.day;
    
    MicroModal.show(
      `daily-action-info-${day}`,
      {
        disableScroll: true,
        awaitOpenAnimation: true,
        awaitCloseAnimation: true
      }
    );
  }
} 