import { Controller } from "stimulus";
import Glide from '@glidejs/glide'

let index = 0;

export default class extends Controller {
  static targets = ["card", "dot"];

  initialize() {
    // this.showCard();
    // setInterval(() => { this.next(); }, 5000);

    new Glide('.glide', {
      type: 'carousel',
      startAt: 0,
      focusAt: 'center',
      autoplay: 10000,
    }).mount()
  }

  next() {
    this.hideCard();
    index == 4 ? (index = 0) : (index += 1);
    this.showCard();
  }

  previous() {
    this.hideCard();
    index == 0 ? (index = 4) : (index -= 1);
    this.showCard();
  }

  hideCard() {
    this.cardTargets[index].classList.add("hidden");
    this.dotTargets[index].classList.add("bg-disable");
  }

  showCard() {
    this.cardTargets[index].classList.remove("hidden");
    this.dotTargets[index].classList.remove("bg-disable");
  }
}
