import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["feature", "image"]

  connect() {
    // Show first feature's image by default and highlight it
    if (this.imageTargets.length > 0) {
      this.imageTargets[0].classList.remove('hidden')
      this.featureTargets[0].classList.add('bg-violet')
    }
  }

  showFeature(event) {
    const clickedFeature = event.currentTarget
    const featureIndex = this.featureTargets.indexOf(clickedFeature)

    // Hide all images and remove background from all features
    this.imageTargets.forEach(image => {
      image.classList.add('hidden')
    })
    this.featureTargets.forEach(feature => {
      feature.classList.remove('bg-violet')
    })

    // Show the selected image and highlight the clicked feature
    if (featureIndex >= 0 && featureIndex < this.imageTargets.length) {
      this.imageTargets[featureIndex].classList.remove('hidden')
      clickedFeature.classList.add('bg-violet')
    }
  }
} 