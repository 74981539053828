// Example usage:
// <div data-controller="tippy" data-tippy-content="Hello world"></div>

import { Controller } from 'stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = ['dropdownContent']

  connect () {
    tippy(this.element, {
      trigger: 'mouseenter focus',
      touch: false,
      duration: [500, 500],
      allowHTML: true,
      arrow: false,
      content: this.dropdownContentTarget.innerHTML,
      placement: 'bottom'
    })
  }

  disconnect () {
    if (this.tippy) {
      this.tippy.destroy()
    }
  }
}
