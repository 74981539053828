import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "toggle",
    "andMore",
    "text",
    "column1",
    "column2",
    "upIcon",
    "downIcon",
  ];

  open() {
    this.column1Target.style.maxHeight = this.column1Target.scrollHeight + "px";
    this.column2Target.style.maxHeight = this.column1Target.scrollHeight + "px";

    this.column1Target.classList.add("content-none", "overflow-hidden");
    this.column1Target.classList.remove("content");
    this.column2Target.classList.add("content-none", "overflow-hidden");
    this.column2Target.classList.remove("content");

    setTimeout(() => {
      this.column1Target.classList.remove("overflow-hidden");
      this.column2Target.classList.remove("overflow-hidden");
    }, 200);

    this.toggleTarget.dataset.action = "click->topics#close";
    this.textTarget.innerHTML = "Hide";
    setTimeout(() => {
      this.andMoreTarget.classList.remove("hidden");
    }, 350);
    this.upIconTarget.classList.remove("hidden");
    this.downIconTarget.classList.add("hidden");
  }

  close() {
    this.column1Target.classList.add("content");
    this.column2Target.classList.add("content");
    this.column1Target.classList.remove("content-none");
    this.column2Target.classList.remove("content-none");

    this.column1Target.style.maxHeight = null;
    this.column2Target.style.maxHeight = null;

    this.toggleTarget.dataset.action = "click->topics#open";
    this.textTarget.innerHTML = "See More";

    this.andMoreTarget.classList.add("hidden");
    this.upIconTarget.classList.add("hidden");
    this.downIconTarget.classList.remove("hidden");
  }
}
