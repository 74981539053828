import { Controller } from "stimulus";

export default class extends Controller {
  static values = { backupUrl: String };

  goBack() {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = this.backupUrlValue;
    }
  }
}