import GLightbox from "glightbox";
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const lightbox = GLightbox({
      touchNavigation: true,
      loop: true,
      autoplayVideos: true,
    });
  }
}
