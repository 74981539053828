import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['question', 'answer', 'arrow']

  initialize () {
    this.questionTargets.forEach(function (question, index) {
      question.setAttribute('data-index', index)
    })
  }

  open (event) {
    this.toggle(this.arrowTargets[event.currentTarget.dataset.index])
    const answer = this.answerTargets[event.currentTarget.dataset.index]
    const question = this.questionTargets[event.currentTarget.dataset.index]

    answer.style.maxHeight = answer.scrollHeight + 'px'
    question.dataset.action = 'click->faq#close'
  }

  close (event) {
    this.toggle(this.arrowTargets[event.currentTarget.dataset.index])
    const answer = this.answerTargets[event.currentTarget.dataset.index]
    const question = this.questionTargets[event.currentTarget.dataset.index]

    answer.style.maxHeight = null
    question.dataset.action = 'click->faq#open'
  }

  toggle (arrow) {
    const btn = arrow
    btn.classList.add('animation')
    btn.classList.toggle('active')
    const newElem = btn.cloneNode(true)
    btn.parentNode.replaceChild(newElem, btn)
    // newElem.addEventListener("click", toggle);
  }
}
