import { Controller } from 'stimulus'
let location = 0
export default class extends Controller {
  static targets = [
    'openButton',
    'closeButton',
    'menu',
    'topBar',
    'scrollableContent',
    'cta'
  ]

  open () {
    // hamburger menu animation has a checkbox that is checked when menu is opened
    const checkbox = document.querySelector('#hamburger-icon')

    // show hamburger menu
    this.menuTarget.classList.remove('hidden')

    if (checkbox) {
      if (!checkbox.checked) {
        this.close()
        return
      }
    }
    // set navbar color as white
    // set class as opened-menu, so scrolling_menu.js doesnt change its colour
    this.topBarTarget.classList.remove('nonscrolling-menu')
    this.topBarTarget.classList.remove('scrolling-menu')
    this.topBarTarget.classList.add('bg-white')
    this.topBarTarget.classList.add('opened-menu')
    this.menuTarget.scrollTop = 0

    // location to scroll to when menu closed
    location = window.pageYOffset

    // hide underlying content
    this.scrollableContentTarget.classList.add('hidden')
    if (this.hasCtaTarget) {
      this.ctaTarget.classList.add('hidden')
    }
    if (document.getElementById('footer')) {
      document.getElementById('footer').classList.add('hidden')
    }
  }

  close () {
    // hide menu content
    this.menuTarget.classList.add('hidden')
    this.topBarTarget.classList.remove('bg-white')

    // show underlying content
    this.scrollableContentTarget.classList.remove('hidden')
    this.topBarTarget.classList.remove('opened-menu')
    if (this.hasCtaTarget) {
      this.ctaTarget.classList.remove('hidden')
    }
    if (document.getElementById('footer')) {
      document.getElementById('footer').classList.remove('hidden')
    }

    // scroll to previous location before menu was opened
    window.scrollTo({ top: location })
  }
}
