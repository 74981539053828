import MicroModal from "micromodal";
import { Controller } from "stimulus";

export default class extends Controller {
  closeSuccessModal() {
    MicroModal.close("success-message");
    document.getElementById("newsletter_subscriber_email").value = "";
  }

  closeFailureModal() {
    MicroModal.close("failure-message");
  }

  showModal(event) {
    MicroModal.show(event.currentTarget.modalName);
  }
}
