import { Controller } from 'stimulus'
import tippy, { inlinePositioning } from 'tippy.js'

export default class extends Controller {
  static targets = ['button', 'content']

  initialize () {
    if (!this.hasButtonTarget) {
      return
    }

    tippy(this.buttonTarget, {
      content: this.contentTarget.innerHTML,
      allowHTML: true,
      trigger: 'click',
      arrow: false,
      interactive: true,
      inlinePositioning: true,
      plugins: [inlinePositioning],
      placement: this.data.get('placementValue'),
      zIndex: this.data.get('zIndexValue')
    })
  }
}
