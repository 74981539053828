import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["carousel"]

  previous(event) {
    const left = event.currentTarget.dataset.size === "sm" ? -256 : -272

    this.carouselTarget.scrollBy({
      left: left,
      behavior: 'smooth'
    });
  }

  next(event) {
    const left = event.currentTarget.dataset.size === "sm" ? 256 : 272

    this.carouselTarget.scrollBy({
      left: left, 
      behavior: 'smooth'
    });
  }
} 