document.addEventListener("turbo:load", () => {
  const checkoutButton = document.getElementById("checkout-button");

  const StripeCheckout = {
    init() {
      this.readyCheckout();
      this.autoCheckout();
    },

    readyCheckout() {
      const stripe = Stripe(checkoutButton.dataset.key);

      checkoutButton.addEventListener("click", function () {
        // Create a new Checkout Session using the server-side endpoint you
        // created in step 3.
        fetch(checkoutButton.dataset.path, {
          method: "POST",
          headers: {
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
              .content,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (session) {
            return stripe.redirectToCheckout({ sessionId: session.id });
          })
          .then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, you should display the localized error message to your
            // customer using `error.message`.
            if (result.error) {
              alert(result.error.message);
            }
          })
          .catch(function (error) {});
      });
    },

    autoCheckout() {
      checkoutButton.click();
    },
  };

  if (checkoutButton) StripeCheckout.init();
});
