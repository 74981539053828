import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.updateImages()
    document.addEventListener('languageChanged', () => this.updateImages())
  }

  updateImages() {
    const lang = document.documentElement.lang
    console.log("Language changed to: ", lang)

    document.querySelectorAll('[data-image-lang]').forEach(section => {
      if (section.dataset.imageLang === lang) {
        section.classList.remove('hidden')
      } else {
        section.classList.add('hidden')
      }
    })
  }
} 