import { MDCTextField } from '@material/textfield'
import { MDCTextFieldHelperText } from '@material/textfield/helper-text'
import { MDCRipple } from '@material/ripple'

import { MDCFormField } from '@material/form-field'
import { MDCCheckbox } from '@material/checkbox'
import { MDCRadio } from '@material/radio'

export const MaterialForms = {
  init() {
    this.readyErrorTextFields()
    this.readyNoLabelTextFields()
    this.readyDateSelectFields()

    // Doesn't look like we need this. Seems to work without.
    // this.readyPrePopulatedTextFields();

    this.initAutoDismissErrors()
    this.initTextFields()

    this.handleBrowserAutocomplete()

    this.initButtons()
    this.initCheckboxs()
    this.initRadio()
  },

  readyErrorTextFields() {
    // add errors to fields where from-group has error. Simple form can't do this via HTML directly.
    const errorTextFields = [].map.call(
      document.querySelectorAll('.form-field.error'),
      function (el) {
        // hide the hints input if present. Harder to do via simple form.
        const hint = el.getElementsByClassName('hint-message')[0]
        if (hint) hint.style.display = 'none'

        const mdcField = el.getElementsByClassName('mdc-text-field')[0]
        if (mdcField) mdcField.classList.add('mdc-text-field--invalid')
      }
    )
  },

  readyNoLabelTextFields() {
    // no floating label label inputs
    const noLabelFields = [].map.call(
      document.querySelectorAll(
        '.form-field.mdc-text-field--no-label .mdc-text-field, .no-label'
      ),
      function (el) {
        el.classList.add('mdc-text-field--no-label')
        el.getElementsByClassName(
          'mdc-notched-outline__notch'
        )[0].classList.add('hidden')
      }
    )
  },

  readyPrePopulatedTextFields() {
    // if the field has a value already - add extra classes.
    const withValueTextFields = [].map.call(
      document.querySelectorAll('.form-field input.mdc-text-field__input'),
      function (el) {
        if (!el.value) return

        const parentField = el.closest('.mdc-text-field')
        parentField.classList.add('mdc-text-field--label-floating')

        const outlineClasses = parentField.querySelector(
          '.mdc-notched-outline'
        ).classList
        outlineClasses.add('mdc-notched-outline--notched')
        outlineClasses.add('mdc-notched-outline--upgraded')

        parentField
          .querySelector('.mdc-floating-label')
          .classList.add('mdc-floating-label--float-above')
      }
    )
  },

  readyDateSelectFields() {
    const dateFormat = gon.date_format
    const dateClasses = {
      Day: 'col-span-3',
      Month: 'col-span-5',
      Year: 'col-span-4',
    }

    const dateSelectFields = [].map.call(
      document.querySelectorAll('.form-field .date-dropdowns-material'),
      function (el) {
        const wrapper = document.createElement('div')
        wrapper.className = 'grid grid-cols-12 gap-4 dynamic_translate'
        el.dataset.controller = 'date-update'

        const selectFields = el.querySelectorAll('select')

        selectFields.forEach((item, i) => {
          item.dataset.action += ' date-update#updateFields'
          let label = 'Date'
          let colClass = ''

          switch (i) {
            case 0:
              label = dateFormat[0]
              colClass = dateClasses[dateFormat[0]]
              item.dataset.dateUpdateTarget = dateFormat[0].toLowerCase();
              break
            case 1:
              label = dateFormat[1]
              colClass = dateClasses[dateFormat[1]]
              item.dataset.dateUpdateTarget = dateFormat[1].toLowerCase();
              break
            default:
              label = dateFormat[2]
              colClass = dateClasses[dateFormat[2]]
              item.dataset.dateUpdateTarget = dateFormat[2].toLowerCase();
          }

          const singleWrapper = document.createElement('div')
          singleWrapper.className = colClass

          singleWrapper.appendChild(
            MaterialForms.generateMdcTextField(item, label)
          )
          wrapper.appendChild(singleWrapper)
        })

        el.innerHTML = ''
        el.appendChild(wrapper)
      }
    )
  },

  initAutoDismissErrors() {
    // dismiss errors on change
    const errorTextFieldListeners = [].map.call(
      document.querySelectorAll(
        '.form-field.error select, .form-field.error input, .form-field.error textarea'
      ),
      function (input) {
        input.addEventListener('change', (event) => {
          const formField = event.target.closest('.form-field')
          formField.classList.remove('error')

          const error = formField.getElementsByClassName('error-message')[0]
          if (error) error.style.display = 'none'

          const hint = formField.getElementsByClassName('hint-message')[0]
          if (hint) hint.style.display = 'block'
        })
      }
    )
  },

  initTextFields() {
    const textFields = [].map.call(
      document.querySelectorAll('.mdc-text-field'),
      function (el) {
        const textarea = el.getElementsByClassName('textarea')[0]

        // if maxfieldheight is set
        if (textarea && textarea.dataset.maxFieldHeight) {
          el.style.maxHeight = textarea.dataset.maxFieldHeight
        }

        // if maxlength for textarea
        if (
          textarea &&
          textarea.maxLength > 0 &&
          !textarea.parentNode.innerHTML.includes(
            'mdc-text-field-character-counter'
          )
        ) {
          textarea.parentNode.innerHTML +=
            '<span class="mdc-text-field-character-counter">0 / ' +
            textarea.maxLength +
            '</span>'
          el.classList.add('mdc-text-field--with-internal-counter')
        }


        // if mdc-text-field__input is not present, ship
        if (!el.getElementsByClassName('mdc-text-field__input')[0]) return

        return new MDCTextField(el)
      }
    )

    const textFieldHelperText = [].map.call(
      document.querySelectorAll('.mdc-text-field-helper-text'),
      function (el) {
        return new MDCTextFieldHelperText(el)
      }
    )
  },

  handleBrowserAutocomplete() {
    // Login page autofill handle
    // https://github.com/material-components/material-components-web/issues/4447
    window.setTimeout(() => {
      document
        .querySelectorAll('.mdc-text-field__input:-webkit-autofill')
        .forEach((el) => {
          const textField = el.parentNode

          const label = textField.querySelector('.mdc-floating-label')
          if (label) {
            label.classList.add('mdc-floating-label--float-above')
          }

          const outline = textField.querySelector('.mdc-notched-outline')
          if (outline) {
            outline.classList.add('mdc-notched-outline--notched')
            outline.classList.add('mdc-notched-outline--upgraded')
          }

          if (textField.MDCTextField) {
            textField.MDCTextField.foundation_.notchOutline(true)
          }
        })
    }, 300)
  },

  initButtons() {
    const buttons = [].map.call(
      document.querySelectorAll('.mdc-button'),
      function (el) {
        return new MDCRipple(el)
      }
    )
  },

  initCheckboxs() {
    const checkboxs = [].map.call(
      document.querySelectorAll('.mdc-checkbox'),
      function (el) {
        const checkbox = new MDCCheckbox(el)
        const formField = new MDCFormField(el.closest('.mdc-form-field'))
        formField.input = checkbox

        return checkbox
      }
    )
  },

  initRadio() {
    const radios = [].map.call(
      document.querySelectorAll('.mdc-radio'),
      function (el) {
        const radio = new MDCRadio(el)
        const formField = new MDCFormField(el.closest('.mdc-form-field'))
        formField.input = radio

        return radio
      }
    )
  },

  // Helper methods

  generateMdcTextField(fieldHtml, labelText) {
    const el = document.createElement('div')
    el.className = 'mdc-text-field mdc-text-field--outlined flex-auto'

    fieldHtml.classList.add('mdc-text-field__input')
    el.appendChild(fieldHtml)

    el.insertAdjacentHTML(
      'beforeend',
      [
        '<div class="mdc-notched-outline">' +
        '<div class="mdc-notched-outline__leading">' +
        '</div>' +
        '<div class="mdc-notched-outline__notch">' +
        '<label class="mdc-floating-label">' +
        labelText +
        '</label>' +
        '</div>' +
        '<div class="mdc-notched-outline__trailing">' +
        '</div>' +
        '</div>'
      ].join('')
    )

    return el
  }
}

document.addEventListener('turbo:load', () => {
  MaterialForms.init()
})

document.addEventListener('turbo:render', () => {
  MaterialForms.init()
})
